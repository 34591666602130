<template>
    <div>
        <v-layout wrap justify-center>
            <v-flex xs12>
                <v-form>
                    <v-layout wrap>
                        
                        <v-flex xs12 align-self-center px-3>
                            <v-flex xs12  px-3>
                                <span class="kumbhBold" style="font-size: 18px"
                                  >Personal Details (Signature shall be added only in png/jpg format)</span
                                >
                              </v-flex>
                            <v-layout wrap pt-4>
                                
                                <v-flex xs12 sm6 align-self-center text-left  px-4  v-if="mydocs && mydocs.employeeDetails" >
                                    <span class="docValue">Employee Name</span>
                              
                                    <v-text-field :hide-details="true" v-model="mydocs.employeeDetails.empname" class="pt-2" disabled outlined dense></v-text-field>
                                </v-flex>
                                <v-flex xs12 sm6 align-self-center text-left px-4 v-if="mydocs && mydocs.documentDetails">
                                    <span class="docValue">Fathers's / Husband's Name</span>
                                   
                                    <v-text-field :hide-details="true" class="pt-2" v-model="mydocs.documentDetails.fathersName" readonly outlined dense></v-text-field>
                                </v-flex>
                                <v-flex xs12 sm6 align-self-center text-left px-4  v-if="mydocs && mydocs.employeeDetails">
                                    <span class="docValue">Date Of Birth</span>
                                   
                                    <v-menu
                                    ref="menu"
                                    v-model="menu" 
                                    :close-on-content-click="false"
                                    :nudge-right="40" :hide-details="true"
                                    transition="scale-transition"
                                    offset-y 
                                    min-width="auto"
                                  >
                                    <template v-slot:activator="{ on, attrs }">
                                      <v-text-field  class="pt-2"
                                        v-model="mydocs.employeeDetails.dob"
                                     :hide-details="true"
                                      
                                        outlined
                                        dense
                                        v-bind="attrs"
                                        v-on="on"
                                        readonly
                                      ></v-text-field>
                                    </template>
                                    <v-date-picker  
                                      v-model="mydocs.employeeDetails.dob"
                                      @input="menu = false"
                                      :max="new Date().toISOString().substr(0, 10)" 
                                      readonly disabled
                                    ></v-date-picker>
                                  </v-menu>
                                </v-flex>
                              
                               
                                <v-flex v-if="mydocs && mydocs.employeeDetails"
                                xs12
                                sm6
                                align-self-center
                                text-left
                                pa-2
                                px-4
                              >
                              <span class="docValue">Gender</span>
                             
                              <v-select 
                                v-model="mydocs.employeeDetails.gender"
                                :items="['Male', 'Female', 'Other']"
                                class="pt-2" :hide-details="true"
                                outlined
                                dense
                                readonly
                              ></v-select>
                              </v-flex>

                                <v-flex xs12 sm6 align-self-center text-left px-4 v-if="mydocs && mydocs.documentDetails">
                                    <span class="docValue">Marital Status</span>
                                   
                                    <v-select
                                        v-model="mydocs.documentDetails.maritalStatus"
                                        :items="['Married', 'Unmarried','Widow','Widower','Divorcee']"
                                        label="Marital Status"
                                        outlined class="pt-2" :hide-details="true"
                                        dense
                                        readonly
                                    ></v-select>
                                </v-flex>

                                <v-flex xs12 sm6 align-self-center text-left  px-4 v-if="mydocs && mydocs.documentDetails">
                                    <span class="docValue">Emergency Contact Number</span>
                                   
                                    <v-text-field v-model="mydocs.documentDetails.emergencyContactNo" :hide-details="true" class="pt-2" outlined type="number"
                                        dense readonly></v-text-field>
                                </v-flex>

                                <v-flex xs12 sm6 align-self-center text-left pt-3 px-4 v-if="mydocs && mydocs.documentDetails">
                                    <span class="docValue">Permanent Address</span>
                                   
                                    <v-textarea v-model="mydocs.documentDetails.permanentAddress"   class="pt-2" outlined dense readonly></v-textarea>
                                </v-flex> 

                                <v-flex xs12 sm6 align-self-center text-left pt-3 px-4 v-if="mydocs && mydocs.employeeDetails">
                                    <span class="docValue">Temporary Address</span>
                                   
                                    <v-textarea v-model="mydocs.employeeDetails.temporaryAddress" class="pt-2" outlined dense readonly></v-textarea>
                                </v-flex> 

                                <v-flex xs12 align-self-center text-left px-4>
                                    <v-layout>
                                        <v-flex>
                                            <span class="itemHeading">Nominee Details</span>
                                        </v-flex>
                                    </v-layout>
                                </v-flex>

                                <v-flex xs12 v-for="(nominee, index) in nomineeDetails" :key="index">
                                    <v-card class="ma-3 pa-3">
                                        <v-layout wrap>
                                            <!-- <v-flex xs12 text-right>
                                                <template v-if="!nominee._id">
                                                    <v-btn icon small color="red" @click="removeNominee(index)" readonly>
                                                        <v-icon>mdi-close</v-icon>
                                                    </v-btn>
                                                </template>
                                                <template v-else>
                                                    <v-icon
                                                        color="red"
                                                        small
                                                        @click="deleteNominee(nominee._id, index)"
                                                        style="cursor: pointer; font-size: 18px;"
                                                        readonly
                                                    >
                                                        mdi-delete
                                                    </v-icon>
                                                </template>
                                            </v-flex> -->
                                            
                                            <v-flex xs12 sm6 pa-2>
                                                <span class="docValue">Name of Nominee</span>
                                               
                                                <v-text-field :hide-details="true" v-model="nominee.nomineeName" class="pt-2" outlined dense readonly></v-text-field>
                                            </v-flex>

                                            <v-flex xs12 sm6 pa-2>
                                                <span class="docValue">Nominee's relationship with the member</span>
                                               
                                                <v-text-field :hide-details="true" v-model="nominee.relation" class="pt-2" outlined dense readonly></v-text-field>
                                            </v-flex>

                                            <v-flex xs12 sm6 align-self-center text-left pa-2 >
                                                <span class="docValue">DOB of Nominee</span>
                                               
                                                <v-menu
                                                ref="menu1"
                                                v-model="nominee.menu1" 
                                                :close-on-content-click="false"
                                                :nudge-right="40"
                                                transition="scale-transition"
                                                offset-y
                                                min-width="auto"
                                                readonly
                                              >
                                                <template v-slot:activator="{ on, attrs }">
                                                  <v-text-field 
                                                    v-model="nominee.nomineeDOB"
                                                  class="pt-2"
                                                    :hide-details="true"
                                                    outlined
                                                    dense
                                                    v-bind="attrs"
                                                    v-on="on"
                                                    readonly
                                                  ></v-text-field>
                                                </template>
                                                <v-date-picker 
                                                  v-model="nominee.nomineeDOB"
                                                  @input="nominee.menu1 = false"
                                                  :max="new Date().toISOString().substr(0, 10)" 
                                                  readonly disabled
                                                ></v-date-picker>
                                              </v-menu>
                                            </v-flex>

                                            <v-flex xs12 sm6 pa-2>
                                                <span class="docValue">Guardian Details</span>
                                               
                                                <v-text-field :hide-details="true" v-model="nominee.guardianDetails"    class="pt-2"  outlined dense readonly></v-text-field>
                                            </v-flex>

                                            <v-flex xs12 sm6 pa-2>
                                                <span class="docValue">Bank Account Number</span>
                                               
                                                <v-text-field :hide-details="true" v-model="nominee.AcNum"   class="pt-2"  outlined dense readonly></v-text-field>
                                            </v-flex>

                                            <v-flex xs12 sm6 pa-2>
                                                <span class="docValue">Bank Name</span>
                                               
                                                <v-text-field v-model="nominee.BankName" :hide-details="true" class="pt-2" outlined dense readonly></v-text-field>
                                            </v-flex>

                                            <v-flex xs12 sm6 pa-2>
                                                <span class="docValue">Branch Name</span>
                                               
                                                <v-text-field v-model="nominee.BranchName" :hide-details="true" class="pt-2"  outlined dense readonly></v-text-field>
                                            </v-flex>

                                            <v-flex xs12 sm6 pa-2>
                                                <span class="docValue">IFSC Code</span>
                                               
                                                <v-text-field v-model="nominee.IFSCCode" :hide-details="true" class="pt-2" outlined dense readonly></v-text-field>
                                            </v-flex>

                                            <v-flex xs12 pa-2>
                                                <span class="docValue">Address Of Nominee</span>
                                               
                                                <v-textarea v-model="nominee.nomineeAddress" :hide-details="true" class="pt-2" outlined dense readonly></v-textarea>
                                            </v-flex>
                                        </v-layout>
                                    </v-card>
                                </v-flex>
                                <!-- <v-flex text-right px-3 pt-2>
                                    <v-btn
                                        color="#00994d"
                                        dark
                                        small
                                        @click="addNominee"
                                        :readonly="true"
                                    >
                                        Add Another Nominee
                                    </v-btn>

                                </v-flex> -->

                                <v-flex xs12 sm12 align-self-center text-left pa-2 px-4>
                                      <span class="kumbhSemibold">Signature</span>
                                     
                                </v-flex>

                                <v-flex xs12 sm12 align-self-center text-left pa-2 px-4>
                                    <v-card outlined class="pa-3">
                                        <v-layout wrap row align-center justify-end pa-4>
                                        
                                            <v-img
                                                v-if="croppedImage"
                                                :src="croppedImage"
                                                max-width="100"
                                                max-height="100"
                                                class="mb-3"
                                            ></v-img>

                                            <v-img
                                                v-else-if="mydocs && mydocs.documentDetails && mydocs.documentDetails.signOfEmp"
                                                :src="mediaURL + mydocs.documentDetails.signOfEmp"
                                                max-width="100"
                                                max-height="100"
                                                class="mb-3"
                                            ></v-img>
                                            <v-flex xs12 sm6 md8 text-right pl-3 v-else>
                                                <span style="color: red; font-size:12px">Signature Is Not Uploaded</span>
                                              </v-flex>

                                            <!-- <v-btn
                                                color="#005f32"
                                                dark
                                                small
                                                @click="$refs.signOfEmp.click()"
                                                class="ml-3"
                                                readonly
                                            >
                                                <span style="font-size: 12px;">{{ mydocs && mydocs.documentDetails && mydocs.documentDetails.signOfEmp ? 'UPLOAD NEW Signature' : 'UPLOAD Signature' }}</span>
                                            </v-btn> -->

                                            <input
                                                v-show="false"
                                                ref="signOfEmp"
                                                type="file"
                                                accept="image/*"
                                                @change="onImageSelected"
                                                readonly
                                            />

                                            <v-dialog v-model="dialog" max-width="500">
                                                <v-card>
                                                    <v-card-title class="headline">Crop your image</v-card-title>
                                                    <v-card-text>
                                                        <Cropper
                                                            ref="cropper"
                                                            :src="imagePreview"
                                                            :aspect-ratio="1"
                                                            :view-mode="1"
                                                            style="width: 100%; height: 300px;"
                                                            readonly
                                                        />
                                                    </v-card-text>
                                                    <v-card-actions>
                                                        <v-spacer></v-spacer>
                                                        <v-btn color="primary" text @click="cropImage" readonly>Crop</v-btn>
                                                        <v-btn color="secondary" text @click="closeDialog" readonly>Cancel</v-btn>
                                                    </v-card-actions>
                                                </v-card>
                                            </v-dialog>
                                        </v-layout>
                                    </v-card>
                                </v-flex>

                            </v-layout>
                        </v-flex>
                    </v-layout>

                    <!-- <v-layout wrap justify-center py-5>
                        <v-flex xs12 text-center>
                          <v-btn color="#005f32" dark @click="add()" readonly
                            ><span>Save Details</span></v-btn
                          >
                        </v-flex>
                      </v-layout> -->
                </v-form>
            </v-flex>

        </v-layout>
    </div>
</template>

<script>
import { Cropper } from "vue-advanced-cropper";
import "vue-advanced-cropper/dist/style.css";
import axios from "axios";

export default {
    components: {
        Cropper,
    },
    data() {
        return {
            imagePreview: null,
            croppedImage: null,
            dialog: false,
            showSnackBar: false,
            timeout: 5000,
            msg: null,
            appLoading: false,
            mydocs: {},
            ServerError: false,
            menu: false,
            menu1: false,
            signOfEmp: "",
            empname: "",
            fathersName: "",
            dob: "",
            gender: "",
            maritalStatus: "",
            emergencyContactNo: "",
            permanentAddress: "",
            temporaryAddress: "",
            nomineeDetails: [{
                nomineeName: "",
                nomineeAddress: "",
                relation: "",
                nomineeDOB: "",
                guardianDetails: "",
                AcNum: "",
                BankName: "",
                IFSCCode: "",
                BranchName: "",
                _id: null
            }],
            formData: new FormData(),
        };
    },
    mounted() {
        this.getMyUploads();
    },
    methods: {
        addNominee() {
            if (this.nomineeDetails.length < 2) {
                this.nomineeDetails.push({
                    nomineeName: "",
                    nomineeAddress: "",
                    relation: "",
                    nomineeDOB: "",
                    guardianDetails: "",
                    AcNum: "",
                    BankName: "",
                    IFSCCode: "",
                    BranchName: "",
                    _id: null
                });
            } 
        },
        removeNominee(index) {
            this.nomineeDetails.splice(index, 1);
        },
        deleteNominee(nomineeId, index) {
            const data = { nominiId : nomineeId };

            axios({
                url: "/employee/nominidetails/delete",
                method: "POST", 
                headers: {
                    "x-auth-token": localStorage.getItem("token"),
                },
                data: data,
            })
            .then((response) => {
                this.appLoading = false;
                if (response.data.status) {
                    this.msg = "Delete Successfully";
                    this.showsnackbar = true;

                    // Remove the nominee locally after successful deletion
                    this.nomineeDetails.splice(index, 1);
                } else {
                    this.msg = response.data.msg;
                    this.showsnackbar = true;
                }
            })
            .catch((err) => {
                this.appLoading = false;
                this.ServerError = true;
                console.log(err);
            });
        },
        save() {
            // Your save logic here including nomineeDetails array
            console.log(this.nomineeDetails);
        },
        getMyUploads() {
            this.appLoading = true;
            axios({
                url: "/employee/nominationFormView",
                method: "GET",
                headers: {
                    "x-auth-token": localStorage.getItem("token"),
                },
                params: {
                   id: this.$route.query.id,
                },
            })
            .then((response) => {
                this.appLoading = false;
                if (response.data.status) {
                    const nomineeData = response.data.data.documentDetails.nomineeDetails || [];
                    this.mydocs = response.data.data;
                    // Assign backend data to nomineeDetails including _id
                    this.nomineeDetails = nomineeData.map((nominee) => ({
                        nomineeName: nominee.nomineeName || "",
                        nomineeAddress: nominee.nomineeAddress || "",
                        relation: nominee.relation || "",
                        nomineeDOB: nominee.nomineeDOB || "",
                        guardianDetails: nominee.guardianDetails || "",
                        AcNum: nominee.AcNum || "",
                        BankName: nominee.BankName || "",
                        IFSCCode: nominee.IFSCCode || "",
                        BranchName: nominee.BranchName || "",
                        _id: nominee._id || null
                    }));
                } else {
                    this.showsnackbar = true;
                    this.msg = response.data.msg;
                }
            })
            .catch((err) => {
                this.appLoading = false;
                this.ServerError = true;
                console.error(err);
            });
        },

        onImageSelected(event) {
            const file = event.target.files[0];
            if (file) {
                this.signOfEmp = file;
                const reader = new FileReader();
                reader.onload = (e) => {
                    this.imagePreview = e.target.result;
                    this.dialog = true;
                };
                reader.readAsDataURL(file);
            }
        },
        cropImage() {
            const result = this.$refs.cropper.getResult();
            if (result) {
                result.canvas.toBlob((blob) => {
                    this.croppedImage = URL.createObjectURL(blob);
                    this.signOfEmp = blob;
                    this.dialog = false;
                });
            }
        },
        closeDialog() {
            this.dialog = false;
        },
        add() {
            let formData = new FormData();
            formData.append("id", localStorage.getItem("userid"));
            formData.append("signOfEmp", this.signOfEmp);
            formData.append("empname", this.mydocs.employeeDetails.empname);
            formData.append("fathersName", this.mydocs.documentDetails.fathersName);
            formData.append("dob", this.mydocs.employeeDetails.dob);
            formData.append("gender", this.mydocs.employeeDetails.gender);
            formData.append("maritalStatus", this.mydocs.documentDetails.maritalStatus);
            formData.append("emergencyContactNo", this.mydocs.documentDetails.emergencyContactNo);
            formData.append("permanentAddress", this.mydocs.documentDetails.permanentAddress);
            formData.append("temporaryAddress", this.mydocs.employeeDetails.temporaryAddress);

            // Convert nomineeDetails array to JSON string before appending
            // Each nominee will have its _id if it exists
            formData.append("nomineeDetails", JSON.stringify(this.nomineeDetails));

            axios({
                method: "POST",
                url: "/employee/nomination/upload",
                data: formData,
                headers: {
                    "x-auth-token": localStorage.getItem("token"),
                    "Content-Type": "multipart/form-data"
                },
            })
            .then((response) => {
                if (response.data.status === true) {
                    this.msg = response.data.msg;
                    this.showSnackBar = true;
                    formData = new FormData();
                    this.appLoading = false;
                    this.getMyUploads();
                } else {
                    this.appLoading = false;
                    this.msg = response.data.msg;
                    this.showSnackBar = true;
                    formData = new FormData();
                }
            })
            .catch((err) => {
                this.ServerError = true;
                console.error(err);
            });
        },
    },
};
</script>